<template>
  <div>
    <div v-if="firstNameCookie || paymentIntent" class="successful-purchase-details">
      <div v-if="paymentIntent" class="if-event mb-3 mt-2">
        <h1 class="mb-3">{{ `${$t('Success.thankYou')} ${paymentIntent.firstName}.` }}</h1>
        <h1 class="mb-3">{{ `${$t('Success.inTheDraw')}!` }}</h1>
        <p class="text-wrap text-left mt-3 mb-0">
          {{ $t('Success.messagePrepend') }}
          <strong>{{ paymentIntent.email }}</strong> {{ $t('Success.message') }}
        </p>

        <JoinPlayerPortal />

        <CartContents
          :title="$t('Success.yourPurchase')"
          :orders="orders"
          :draws="numDraws"
          :last-draw-date="lastDrawDate"
        />

        <div class="downloadTicketsContainer">
          <p v-if="displayDownloadTickets">{{ $t('Success.downloadTicketsMessage') }}</p>

          <ErrorHandler v-if="errorMessage" :error="errorMessage" />

          <DownloadTicketsButton />
        </div>
        <!-- Customer Details -->
        <div class="customer-details align-self-start">
          <h2 class="mb-3">{{ $t('Success.Your details') }}</h2>
          <b-row>
            <b-col cols="12">
              <p>
                {{ `${paymentIntent.firstName} ${paymentIntent.lastName}` }}
              </p>
              <p>{{ paymentIntent.address }}</p>
              <p>{{ paymentIntent.city }}, {{ paymentIntent.province }}</p>
              <p>{{ formatPostal(paymentIntent.postal) }}</p>
              <p>{{ formatPhone(paymentIntent.phone) }}</p>
              <p>
                <strong>{{ paymentIntent.email }}</strong>
              </p>
            </b-col>
          </b-row>
        </div>

        <div v-if="paymentIntent.secondaryName" id="secondary-name-container" class="align-self-start mt-5">
          <h2 class="mb-3">{{ $t('Success.Additional Names') }}</h2>
          <b-row>
            <b-col cols="12">
              <p>{{ `${paymentIntent.secondaryName}` }}</p>
            </b-col>
          </b-row>
        </div>

        <b-button
          v-if="!$store.state.showCreateOnlineOrderButton"
          :href="redirectUrl"
          variant="success"
          class="mt-5"
        >
          {{ $t('Success.Back to draw') }}
        </b-button>

        <b-button v-else :href="$store.state.createOnlineOrderRedirectUrl" variant="success" class="mt-5">
          {{ $t('Success.Create Online Order') }}
        </b-button>
      </div>

      <div v-else-if="firstNameCookie && emailCookie" class="d-flex flex-column mb-3 mt-2 m-auto">
        <h1 class="mb-3 text-center">{{ `${$t('Success.forgetSomething')} ${firstNameCookie}?` }}</h1>
        <p class="text-left mb-0">{{ `${$t('Success.messagePrepend')}` }}</p>
        <p class="text-center text-bold mb-4">{{ emailCookie }}</p>
        <div class="downloadTicketsContainer">
          <p v-if="displayDownloadTickets">{{ $t('Success.forgetSomethingMessage') }}</p>

          <ErrorHandler v-if="errorMessage" :error="errorMessage" />

          <DownloadTicketsButton />
        </div>
      </div>
    </div>
    <div v-else-if="firstNameCookie === null" class="expired-container">
      <img
        class="d-block m-auto"
        :src="renderImage('RaffleboxLogos/WebsiteAssets/5050-hero-image.png', { width: 211 })"
        alt=""
      />

      <h1 class="text-center mt-3">{{ $t('Success.thisPageHasExpired') }}</h1>
      <div class="text-center">
        <p class="mt-2">
          {{ $t('Success.orderConfirmationExpirationMessage') }}
        </p>
        <div class="button-container">
          <b-button :href="liveRafflesUrl" variant="success" class="mt-3">
            {{ $t('Success.returnToLiveRaffles') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import VueCookie from 'vue-cookie';
import CartContents from '@/components/CartContents.vue';
import ErrorHandler from '@/components/ErrorHandler.vue';
import JoinPlayerPortal from '@/components/JoinPlayerPortal.vue';
import DownloadTicketsButton from '@/components/DownloadTicketsButton.vue';

export default Vue.extend({
  name: 'Success',
  components: {
    CartContents,
    ErrorHandler,
    JoinPlayerPortal,
    DownloadTicketsButton
  },
  data() {
    return {
      paymentIntent: this.$store.state.intent || null,
      orders: this.$route.params.orders ? JSON.parse(this.$route.params.orders) : null,
      siteUrl: config.RAFFLEBOX_URL || 'https://rafflebox.ca',
      shortlink: this.$store.state.event.shortlink || null,
      eventId: this.$store.state.event.id || null,
      numDraws: this.$route.params.numDraws || null,
      lastDrawDate: this.$route.params.lastDrawDate || null,
      errorMessage: undefined as string | undefined,
      firstNameCookie: null,
      emailCookie: null,
      liveRafflesUrl: `${config.RAFFLEBOX_URL}/raffles`
    };
  },
  computed: {
    displayDownloadTickets() {
      const tld = config.RAFFLEBOX_URL.split('.').pop();
      if (tld === 'us') {
        return false;
      }
      return true;
    },
    redirectUrl() {
      // Sick Kids Foundation Prod ID, Patrick Ontario Mock Test ID
      if (['a2ee0453-3b48-4e75-bc0b-7f8b981d6cbe', '52a8c67c-49e0-4499-8d89-c5ecff456cca'].includes(this.eventId)) {
        return 'https://www.sickkidsfoundation.com/pages/lot/taylorswiftsweeps';
      } else {
        return `${this.siteUrl}/raffle/${this.shortlink}`;
      }
    }
  },
  methods: {
    createCookies() {
      VueCookie.set('firstName', this.paymentIntent.firstName, { expires: 7 });
      VueCookie.set('email', this.paymentIntent.email, { expires: 7 });
    },
    deleteCookies() {
      VueCookie.delete('firstName');
      VueCookie.delete('email');
    }
  }
});
</script>

<style lang="scss" scoped>
.successful-purchase-details {
  border: 2px solid #dfe2e2;
  border-radius: 12px;
  display: flex !important;
  padding: 40px 60px;
  margin: 50px auto;
  max-width: 50rem;
  width: 100%;
  font-size: 1.25rem;

  .stripe-intent-error {
    font-size: 1rem !important;
  }

  .text-bold {
    font-weight: bold;
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.expired-container {
  display: flex !important;
  flex-direction: column;
  padding: 40px 60px;
  margin: 50px auto;
  max-width: 50rem;
  width: 100%;
  font-size: 1.25rem;
}

h1 {
  font-family: $heading;
  font-size: 3rem;
  font-weight: 600;
  margin: 0rem 0 1rem;
}

h2 {
  font-family: $heading;
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0 1.5rem;
}

.if-event {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.event-text {
  font-size: 1.5rem;
}

.text-wrap {
  max-width: 35rem;
  text-align: center;
}

.sub-text {
  margin-bottom: 0;
}

.customer-details {
  p {
    margin-bottom: 0;
  }
}

.ticket-link {
  display: inline-block;
  padding: 0.4rem 0;
  color: #212529;
  text-decoration: underline;
}

.donation-area {
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #787878;
  border-radius: 10px;
  width: 100%;
  font-size: 20px;
  margin-top: 2rem;
}

.details {
  padding: 1rem 1rem;
  font-size: 20px;

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    .downloadingMessage {
      margin-top: 1rem;
      text-align: center;
      font-size: 1rem;

      p {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin-bottom: 1rem;
  }

  .bold {
    font-weight: bold;
  }
}

.header {
  width: '100%';
  height: '100%';
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #15803d;
  color: #ffffff;
  border-bottom: 1px solid #787878;
  border-radius: 10px 10px 0 0;
  font-family: $heading;

  h4 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.5rem !important;
  }

  .details {
    font-size: 1rem;
    padding: 1rem;
  }

  .successful-purchase-details {
    font-size: 1rem;
    padding: 15px;
  }

  .card-contents {
    .dotted-line {
      min-width: 7rem;
    }
  }
}
</style>
