<template>
  <div>
    <div class="text-center">
      <div
        v-if="(waitingForPaymentResponse || loadingPage) && !errorCode"
        class="d-flex align-items-center justify-content-center flex-column mb-3 mt-2"
      >
        <b-spinner class="big-spinner" variant="secondary"></b-spinner>
      </div>
    </div>
    <div>
      <div id="main-container" class="summary">
        <ErrorHandler v-if="errorCode" :error="errorCode" />
        <CartContents v-if="!errorCode" :title="event.name" :error="errorCode" />
      </div>

      <div v-if="!isCartEmpty && !errorCode" class="payment-form-container">
        <StripeGoogleApplePayment
          v-if="!loadingPage"
          :cart="formatCart"
          :donation-amount="donationAmountCents"
          :num-draws="numDraws"
          :customer="customer"
          :show-create-online-order-button="$store.state.showCreateOnlineOrderButton"
          :shipping-information="shippingInformation"
          :event="event"
          @enable-spinner="enableSpinner"
          @disable-spinner="disableSpinner"
          @scroll-to-alert="scrollToAlert"
          @error-found="errorFound"
          @stripe-intent-error="handleStripeIntentError"
        />
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <b-form class="mb-5 payment-form" @submit.prevent>
            <h2 class="mb-5">{{ $t('Payment.billingAddress') }}</h2>

            <!--Age and Title-->
            <div v-if="showAgeAndTitle()">
              <TitleSelect @title-input="handleTitleInput" />
              <AgeSelect @age-input="handleAgeInput" />
            </div>

            <!-- First Name -->
            <ValidationProvider v-slot="firstName" name="first name" rules="required|min:2">
              <b-form-group id="input-group-first-name" :label="$t('First Name')" label-for="input-first-name">
                <b-form-input
                  id="input-first-name"
                  v-model="customer.firstName"
                  :state="getValidationState(firstName)"
                  aria-describedby="input-first-name-feedback"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-first-name-feedback">{{
                  firstName.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <!-- Last Name -->
            <ValidationProvider v-slot="lastName" name="last name" rules="required|min:2">
              <b-form-group id="input-group-last-name" :label="$t('Last Name')" label-for="input-last-name">
                <b-form-input
                  id="input-last-name"
                  v-model="customer.lastName"
                  :state="getValidationState(lastName)"
                  aria-describedby="input-last-name-feedback"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-last-name-feedback">{{
                  lastName.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <!-- Address -->
            <ValidationProvider v-slot="address" name="address" rules="required|min:3">
              <b-form-group id="input-group-address" :label="$t('Address')" label-for="input-address">
                <b-form-input
                  id="input-address"
                  v-model="customer.address"
                  :state="getValidationState(address)"
                  aria-describedby="input-address-feedback"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-address-feedback">{{ address.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <!-- City -->
            <ValidationProvider v-slot="city" name="city" rules="required|min:3">
              <b-form-group id="input-group-city" :label="$t('City')" label-for="input-city">
                <b-form-input
                  id="input-city"
                  v-model="customer.city"
                  :state="getValidationState(city)"
                  aria-describedby="input-city-feedback"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-city-feedback">{{ city.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <CountryInput v-model="customer.country" :validate-postal-code-location="!event.disablePostalCheckV2" />

            <ProvinceStateSelect
              v-if="customer.country"
              :country="customer.country"
              :event-province="event.province"
              :validate-postal-code-location="!event.disablePostalCheckV2"
              @province-input="handleProvinceInput"
            />

            <PostalZipInput
              v-if="customer.country"
              v-model="customer.postal"
              :country="customer.country"
              :province="event.province"
              :validate-postal-code-location="!event.disablePostalCheckV2"
              parent-name="payment"
            />

            <!-- Telephone -->
            <ValidationProvider v-slot="phone" name="phone number" rules="required|phoneNumberLength">
              <b-form-group id="input-group-phone" :label="$t('Phone Number')" label-for="input-phone">
                <vue-phone-number-input
                  id="input-phone-number"
                  v-model="customer.phone"
                  default-country-code="CA"
                  no-country-selector
                  no-example
                  :state="getValidationState(phone)"
                  valid-color="#28a745"
                  error-color="#dc3545"
                  placeholder=""
                  no-validator-state
                  max-length="14"
                  :class="[
                    { 'is-invalid': getValidationState(phone) === false },
                    { 'is-valid': getValidationState(phone) === true }
                  ]"
                />
                <b-form-invalid-feedback id="input-phone-feedback" :state="getValidationState(phone)">{{
                  phone.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationObserver>
              <!-- Email Address -->
              <ValidationProvider v-slot="email" name="email" vid="email" rules="required|email">
                <b-form-group
                  id="input-group-email"
                  :label="$t('Email Address')"
                  label-for="input-email"
                  :description="$t('Payment.emailHint')"
                >
                  <b-form-input
                    id="input-email"
                    v-model="customer.email"
                    :state="getValidationState(email)"
                    aria-describedby="input-email-feedback"
                    type="email"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-email-feedback">{{ email.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <!-- Confirm Email Address -->
              <ValidationProvider
                v-slot="confirmEmail"
                name="confirm email"
                vid="confirmEmail"
                rules="required|confirmEmail:@email|email"
              >
                <b-form-group
                  id="input-group-email"
                  :label="$t('Confirm Email Address')"
                  label-for="input-confirm-email"
                >
                  <b-form-input
                    id="input-confirmEmail"
                    v-model="customer.confirmEmail"
                    :state="getValidationState(confirmEmail)"
                    aria-describedby="input-confirm-email-feedback"
                    type="email"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-confirm-email-feedback">{{
                    confirmEmail.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </ValidationObserver>

            <b-form-group v-if="event.settings.allowSecondaryName" class="gifted-ticket">
              <b-form-checkbox v-model="isGiftedTicket" @click="displayAdditionalName">
                {{ $t('Gift Ticket') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Additional Names -->
            <ValidationProvider
              v-if="event.settings.allowSecondaryName"
              v-slot="secondaryName"
              name="Additional Names"
              rules="max:255"
            >
              <b-form-group
                id="input-group-additional-names"
                :label="$t('Additional Names')"
                label-for="input-additional-names"
                :hidden="!isGiftedTicket"
              >
                <b-form-input
                  id="input-additional-names"
                  v-model="customer.secondaryName"
                  :state="getValidationState(secondaryName)"
                  aria-describedby="input-additional-names-feedback"
                  type="text"
                ></b-form-input>
                <b-form-text>
                  <p class="mb-0">{{ $t('Gift Name') }}</p>
                  <p>
                    {{ $t('Disclaimer') }}
                  </p>
                </b-form-text>
                <b-form-invalid-feedback id="input-additional-names-feedback">{{
                  secondaryName.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <!-- Shipping Information -->
            <ShippingInformation
              v-if="showShippingInformationSection()"
              @shipping-data-changed="handleShippingDataChanged"
            />

            <!--Age check-->
            <AgeCheck
              v-if="event.province !== 'SK'"
              :country="customer.country"
              :event="event"
              :province="event.province"
              :validate-postal-code-location="!event.disablePostalCheckV2"
              :customer="customer"
            />
            <!-- CASL -->
            <CASLOptInInput :customer="customer" />

            <!-- Payment Information -->
            <div class="payment-info">
              <h4>{{ $t('Payment Information') }}</h4>
              <StripePaymentForm
                :customer="customer"
                :is-player="!!player"
                :form-invalid="invalid"
                :cart="formatCart"
                :donation-amount="donationAmountCents"
                :num-draws="numDraws"
                :show-create-online-order-button="$store.state.showCreateOnlineOrderButton"
                :shipping-information="shippingInformation"
                @enable-spinner="enableSpinner"
                @disable-spinner="disableSpinner"
                @scroll-to-alert="scrollToAlert"
                @error-found="errorFound"
                @stripe-intent-error="handleStripeIntentError"
              />
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { ErrorCodes, ProvinceState } from '@rafflebox-technologies-inc/rafflebox-schema';
import ProvinceStateSelect from '@/components/ProvinceStateSelect.vue';
import PostalZipInput from '@/components/PostalZipInput.vue';
import CartContents from '@/components/CartContents.vue';
import CountryInput from '@/components/CountryInput.vue';
import ErrorHandler from '@/components/ErrorHandler.vue';
import StripePaymentForm from '@/components/StripePaymentForm.vue';
import StripeGoogleApplePayment from '@/components/StripeGoogleApplePayment.vue';
import CASLOptInInput from '@/components/CASLOptInInput.vue';
import AgeCheck from '@/components/AgeCheck.vue';
import ShippingInformation, { ShippingInformationData } from '@/components/ShippingInformation.vue';

import { getProvinceStateOptions } from '@/lib/province-options';
import config from '@/config';
import logger from '@/lib/logger';
import shoppingCart from '@/model/shopping-cart';

import { CheckoutErrorCodes } from '@/lib/error-codes';

import { Customer } from '@/lib/schema/customer.schema';
import AgeSelect from '@/components/AgeSelect.vue';
import TitleSelect from '@/components/TitleSelect.vue';
import { mapState } from 'vuex';

interface Flags {
  [k: string]: boolean | null;
}

export default Vue.extend({
  components: {
    StripePaymentForm,
    ErrorHandler,
    CartContents,
    StripeGoogleApplePayment,
    ProvinceStateSelect,
    PostalZipInput,
    CountryInput,
    CASLOptInInput,
    AgeCheck,
    ShippingInformation,
    AgeSelect,
    TitleSelect
  },
  data() {
    return {
      loadingPage: false,
      isGiftedTicket: false,
      event: this.$store.state.event,
      addon: this.$store.state.addon,
      provinceOptions: {} as ProvinceState[],
      waitingForPaymentResponse: false as boolean,
      customer: {
        firstName: '',
        lastName: '',
        secondaryName: '',
        email: '',
        confirmEmail: '',
        address: '',
        city: '',
        phone: '',
        postal: '',
        province: '',
        country: this.$store.state.event.country,
        caslOptIn: null,
        ageCheck: null,
        age: null,
        title: null
      } as Customer,
      shippingInformation: {} as ShippingInformationData,
      siteUrl: config.RAFFLEBOX_URL,
      isCartEmpty: shoppingCart.isEmpty(),
      numDraws: Number(shoppingCart.numDraws),
      errorCode: '' as ErrorCodes | string
    };
  },
  computed: {
    ...mapState(['player']),
    formatCart() {
      return shoppingCart.items().map((item: any) => {
        return {
          numPackages: item.quantity,
          ticketPackageId: item.id
        };
      });
    },
    cartTotal() {
      return shoppingCart.totalCartWithProcessingFee();
    },
    donationAmountCents() {
      return shoppingCart.totalDonationCents();
    },
    player(): Customer | null {
      return this.$store.state.player;
    }
  },
  watch: {
    player: {
      handler(newPlayer) {
        this.fillCustomerInfo(newPlayer);
      },
      immediate: true
    }
  },
  async beforeMount() {
    this.loadingPage = true;
  },
  async mounted() {
    window.scrollTo(0, 0);

    if (['test', 'production'].includes(config.ENV)) {
      if (this.$gtm.enabled()) {
        // Build array of current cart
        const products = shoppingCart.items().map((tp) => {
          const price = tp.priceCents / 100;

          return {
            // Linter complains about non-camelCase
            /* eslint-disable */
            item_id: tp.id,
            item_name: this.$store.state.event.name,
            price: price,
            item_category: this.$store.state.event.category,
            quantity: tp.quantity,
            item_variant: `${tp.numTickets} for $${price}`,
            index: 0
            /* eslint-enable */
          };
        });

        (window as any).dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            currency: this.$store.state.event.currency.toUpperCase(),
            value: shoppingCart.totalCart(),
            items: products
          }
        });
      }
    }

    try {
      if (shoppingCart.totalCents === 0) {
        this.errorCode = CheckoutErrorCodes.MissingTotalAmountInCart;
      }

      logger.trace('Loading payment form', this.event.Id);
      this.provinceOptions = getProvinceStateOptions(
        this.event.country,
        this.event.province,
        this.event.disablePostalCheckV2
      );

      if (this.$i18n.locale === 'fr') {
        this.provinceOptions.forEach((option) => {
          switch (option.name) {
            case 'British Columbia':
              option.name = 'Colombie-Britannique';
              break;
            case 'New Brunswick':
              option.name = 'Nouveau-Brunswick';
              break;
            case 'Newfoundland and Labrador':
              option.name = 'Terre-Neuve-et-Labrador';
              break;
            case 'Nova Scotia':
              option.name = 'Nouvelle-Écosse';
              break;
            case 'Prince Edward Island':
              option.name = 'Île-du-Prince-Édouard';
              break;
            case 'Quebec':
              option.name = 'Québec';
              break;
            case 'Northwest Territories':
              option.name = 'Territoires du Nord-Ouest';
              break;

            case 'Saskatchewan':
            case 'Alberta':
            case 'Manitoba':
            case 'Nunavut':
            case 'Ontario':
            case 'Yukon':
              break;
          }
        });
      }

      // Auto-fill customer data if player data exists
      if (this.player) {
        this.fillCustomerInfo(this.player);
      }
    } catch (error: any) {
      this.errorCode = error.message;

      logger.error(`Loading payment form failed - ${error.message}`, {
        email: this.customer ? this.customer.email : undefined,
        error
      });
    }

    this.loadingPage = false;
  },
  methods: {
    getInitialCustomerData() {
      return {
        firstName: '',
        lastName: '',
        secondaryName: '',
        email: '',
        confirmEmail: '',
        address: '',
        city: '',
        phone: '',
        postal: '',
        province: this.customer.province,
        country: this.customer.country,
        caslOptIn: null,
        ageCheck: null,
        age: null,
        title: null
      };
    },
    fillCustomerInfo(player: Customer | null) {
      if (player) {
        this.customer = {
          firstName: player.firstName,
          lastName: player.lastName,
          secondaryName: '',
          email: player.email,
          confirmEmail: player.email,
          address: player.address,
          city: player.city,
          phone: player.phone,
          postal: player.postal,
          province: player.province,
          country: player.country,
          caslOptIn: null,
          ageCheck: null,
          age: null,
          title: null
        };
      } else {
        this.resetForm();
      }
    },
    resetForm() {
      this.customer = this.getInitialCustomerData();
    },
    ticketPackagePrice(numTickets: number, quantity: number) {
      return numTickets * quantity;
    },
    getValidationState({ dirty, validated, valid = null }: Flags) {
      return dirty || validated ? valid : null;
    },
    async errorFound() {
      // Handles form input valildation errors
      const validationObserver = this.$refs.observer as any;
      const validationResult = await validationObserver.validateWithInfo();
      const fieldsWithErrors: string[] = [];

      for (const field in validationResult.errors) {
        if (validationResult.errors[field].length > 0) {
          fieldsWithErrors.push(field);
        }
      }

      const firstFieldWithError = document.getElementById(`input-${fieldsWithErrors[0].split(' ').join('-')}`);

      if (firstFieldWithError) {
        firstFieldWithError.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    },
    handleStripeIntentError(errorCode: ErrorCodes) {
      this.errorCode = errorCode;
    },
    enableSpinner() {
      this.waitingForPaymentResponse = true;
    },
    disableSpinner() {
      this.waitingForPaymentResponse = false;
    },
    scrollToAlert() {
      const alert = document.getElementById('main-container');

      if (alert) {
        alert.scrollIntoView(false);
      }
    },
    displayAdditionalName() {
      this.isGiftedTicket = !this.isGiftedTicket;
    },
    handleProvinceInput(value: string) {
      this.customer.province = value;
    },
    handlePostalInput(value: string) {
      this.customer.postal = value;
    },
    handleShippingDataChanged(value: ShippingInformationData) {
      this.shippingInformation = value;
    },
    handleAgeInput(value: string) {
      this.customer.age = value;
    },
    handleTitleInput(value: string) {
      this.customer.title = value;
    },
    showShippingInformationSection() {
      return config.SHIPPING_INFO_ENABLED_ORGS.includes(this.$store.state.event.organizationId);
    },
    showAgeAndTitle() {
      return config.SHOW_AGE_TITLE_SELECT_ORGS.includes(this.$store.state.event.organizationId);
    }
  }
});
</script>

<style lang="scss" scoped>
.cart {
  margin: 1rem 0;
  border-bottom: 1px solid $slate;

  h3 {
    font-size: 1.5rem;
  }

  ul {
    padding: 0 0 0 1rem;
    margin: 0 0 1rem;
    list-style: none;
  }

  p {
    flex: 1 0 auto;
    margin-bottom: 0;
  }

  &__total {
    font-family: $heading;
    font-weight: 600;
    font-size: 2rem;
    text-align: right;
  }

  .dotted-line {
    width: 100%;
    border-bottom: 1px dashed;
    margin: 0 5px 3px 5px;
  }
}

.payment-form {
  margin-top: 0.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid $slate;
}

.payment-info {
  margin: 2rem 0;

  h4 {
    margin: 0;
    padding: 1.5rem 0;
  }
}

.callout {
  position: relative;
  margin-bottom: 1rem;
  padding: 1.5rem 3rem;
  padding-left: 2rem;
  color: $black;
  background-color: $white;
  border: 1px solid $dangerRed;
  border-left: 0.5rem solid $dangerRed;
  border-radius: 0.5rem;
  box-shadow: none;
  text-align: left;

  .alert-link {
    padding-left: 0.2rem;
    color: $dangerRed;
  }
}

.form-group {
  font-family: $body;
  margin-bottom: 2rem;

  input {
    padding: 0.6rem 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    height: 2.8rem;
  }
}
</style>

<style lang="scss">
.vue-phone-number-input {
  &:focus {
    outline: none;
  }

  &.is-invalid input {
    border-color: #dc3545 !important;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }

  &.is-valid input {
    border-color: #28a745 !important;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }
  }

  &.is-focused input {
    box-shadow: none;
  }

  .input-tel {
    &:focus {
      outline: none;
    }

    label {
      display: none !important;
    }

    input {
      cursor: auto;
      display: block;
      width: 100%;
      height: 2.8rem;
      padding: 0.6rem 1rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.5rem !important;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid;
        box-shadow: none;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
}

.invalid-feedback-phone {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>

<style lang="scss" scoped>
.gifted-ticket {
  margin-bottom: 0.5rem;
  label {
    display: flex;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;

    p {
      margin: 0.2rem 0 0 0;
      order: 2;
      font-family: $body;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: 48em) {
  .gifted-ticket {
    label {
      font-size: 1.25rem;

      p {
        margin-top: 0;
      }
    }
  }
}
</style>
