import Vue from 'vue';
import Vuex from 'vuex';
import { Country } from '@rafflebox-technologies-inc/rafflebox-schema';

import config from '@/config';
import StripeService, { Stripe } from '@/lib/stripe';
import GoldrushService from '@/lib/services/goldrush-service-v2';
import { Event, EventCheckoutType, GoldrushMasterEvent, WhiteLabelSettings } from '@/lib/schema/event.schema';
import { Customer } from '@/lib/schema/customer.schema';

Vue.use(Vuex);

export interface LocationService {
  required: string;
  found: string;
}

export default new Vuex.Store({
  state: {
    event: {} as Event,
    addon: {} as Event,
    goldrushMasterEvent: {} as GoldrushMasterEvent,
    intent: null,
    specialEvent: false,
    location: {} as LocationService,
    category: EventCheckoutType.single,
    whiteLabelSettings: {} as WhiteLabelSettings,
    stripe: {} as Stripe,
    currentPage: 1,
    showCreateOnlineOrderButton: false,
    createOnlineOrderRedirectUrl: '',
    playerId: '',
    player: null as any,
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      phone: '',
      postal: '',
      province: '',
      country: ''
    } as Customer,
    phase1: false,
  },
  mutations: {
    updateEvent(state, event) {
      state.event = event;
    },
    updateAddon(state, addon) {
      state.addon = addon;
    },
    updatePaymentIntent(state, intent) {
      state.intent = intent;
    },
    isSpecialEvent(state, toggle) {
      state.specialEvent = toggle;
    },
    updateEventCategory(state, category) {
      state.category = category;
    },
    updateGrMasterEvent(state, event) {
      state.goldrushMasterEvent = event;
    },
    addCustomization(state, whitelabelSettings) {
      state.whiteLabelSettings = whitelabelSettings;
    },
    addLocation(state, location) {
      state.location.required = location.required;
      state.location.found = location.found;
    },
    setStripe(state, stripe: Stripe) {
      state.stripe = stripe;
    },
    updateCurrentPage(state, page) {
      state.currentPage = page;
    },
    setCreateOnlineOrderButton(state, show: boolean) {
      state.showCreateOnlineOrderButton = show;
    },
    setRedirectUrl(state, url: string) {
      state.createOnlineOrderRedirectUrl = url;
    },
    setPlayerId(state, playerId: string) {
      state.playerId = playerId;
    },
    setPlayer(state, player: any) {
      state.player = player;
    },
    setCustomer(state, customer: Customer) {
      state.customer = customer;
    },
    setPhase1(state, phase1: boolean) {
      state.phase1 = phase1;
    }
  },
  actions: {
    updatePaymentIntent({ commit }, intent) {
      commit('updatePaymentIntent', intent);
    },
    async fetchGrMasterEvent({ commit }, params) {
      return GoldrushService.list(params)
        .then((list) => {
          // The master event is the only item in the array.
          commit('updateGrMasterEvent', list.shift());
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    async setStripe({ commit }, params: { countryCode: Country; accountId: string }) {
      return StripeService.retrieveStripePlatformAccount(params.countryCode, params.accountId)
        .then((stripe) => {
          commit('setStripe', stripe);
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    setShowCreateOnlineOrderButton({ commit }, show: boolean) {
      commit('setCreateOnlineOrderButton', show);
    },
    setRedirectUrl({ commit }, url: string) {
      commit('setRedirectUrl', url);
    },
    setPlayerId({ commit }, playerId: string) {
      commit('setPlayerId', playerId);
    },
    setPlayer({ commit }, player: any) {
      commit('setPlayer', player);
    },
    setCustomer({ commit }, customer: Customer) {
      commit('setCustomer', customer);
    }
  },
  getters: {
    returnLink: (state) => {
      switch (state.category) {
        case EventCheckoutType.goldrush:
          return 'goldrush';
        case EventCheckoutType.multiraffle:
          return 'raffle';
        default:
          return 'single';
      }
    },
    isGoldrush: (state) => {
      return state.category === EventCheckoutType.goldrush;
    },
    goldrushSignupLink: (state) => {
      const siteUrl = config.RAFFLEBOX_URL || 'https://rafflebox.ca';
      return `${siteUrl}/goldrush/signup-selection?id=${state.event.organizationId}`;
    },
    displaySecondaryNameField: (state) => {
      return state.event.settings.allowSecondaryName;
    },
    displayCustomBanner: (state) => {
      if (state.whiteLabelSettings) {
        return state.whiteLabelSettings.enabled;
      }

      return true;
    },
    buttonColor: (state) => {
      if (state.whiteLabelSettings && state.whiteLabelSettings.enabled && state.whiteLabelSettings.buttonColor) {
        const regex = /^#([0-9a-f]{3}){1,2}$/i;

        if (regex.test(state.whiteLabelSettings.buttonColor)) {
          return `--buttonColor: ${state.whiteLabelSettings.buttonColor}`;
        }
      }

      // Default Rafflebox Colour
      return '--buttonColor: #56b65e';
    },
    playerId: (state) => state.playerId,
    player: (state) => state.player,
    customer: (state) => state.customer,
    phase1: (state) => state.phase1
  }
});
